import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
    <Layout>
        <SEO title="Terms Of Use"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Terms Of Use</h1>
                </div>
            <p>
               Version: July, 2020
            </p>
            <p>The General Terms of Use Agreement governs your use of the software Application Karma Hunter for mobile devices.</p>
            <h2>Changes To The Terms</h2>
            <p>
               We may modify the Terms from time to time. The most current version of the Terms will be located here. You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service. If we make material changes to these Terms, we will notify you by email, by posting notice on the Service, and/or by other method prior to the effective date of the changes. We will also indicate at the top of this page the date that such changes were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you. You understand and agree that your continued access to or use of the Service after the effective date of changes to the Terms represents your acceptance of such changes.
            </p>
            <h2>Using The Service</h2>
            <p>
               We grant you permission to use the Service subject to these Terms. Your use of the Service is at your own risk, including the risk that you might be exposed to Content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.
               Service Availability. The Service may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability. You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service. You are responsible for maintaining the confidentiality of your Account password. You are also responsible for all activities that occur in connection with your Account. You agree to notify us immediately of any unauthorized use of your Account.</p>
            <h2>Term and Termination</h2>
            <p>Karma Hunter offers a monthly Paid Subscription ("Karma Hunter Plus"). The Agreement is valid during the Paid Subscription Period. If subscribed, a monthly charged will be applied to your account. Upon expiration of the Paid Subscription Period, the Paid Subscription and the Agreement will be automatically renewed unless canceled 24 prior to the expiration date. The Service are available for one (1) month from the date of subscription start and the Agreement will then apply during this period.
               After the Paid Subscription Period or a cost free trial period has ended, the Service are automatically downgraded to the subscription type “Free”. The Agreement is valid during the Free subscription period. Users can chose to terminate this agreement by deleting their account. We reserve the right to close your Account or limit access to some features at any time for any reason such as, but not limited to,
            </p>
            <p>
               (i) licensing, sublicensing, selling, reselling,leasing, transfering, assigning, distributing any content generated by Karma Hunter.
            </p>
            <p>
               (ii) attempt to gain unauthorized access to the Service or its related systems or networks.
            </p>
            <p>
               (iii) modify,
               copy or create derivative work based on the Service or disassemble, reverse engineer, or decompile the Service.
            </p>
            <p>
               (iv) performing malicious and or abusive actions against our different services. Including but not limited to, intentionally or unintetionally triggering rate limits and causing service interruptions.
            </p>
            You may terminate the Terms at any time by deleting your Account.
            <h2>Disclaimers and Limitations of Liability</h2>
            <p>
               By accessing or using the service, you represent that you have read, understood, and agree to these terms, including this section.
               The service and service content are made available to you on an “as is”, “with all faults” and “as available” basis. As such, your use of the service is at your own discretion and risk. The Karma Hunter entities make no claims or promises about the quality, completeness, accuracy, or reliability of the service, its safety or security, the service content. Accordingly, the Karma Hunter entities are not liable to you for any personal injury, loss or damage that might arise, for example, from the service’s inoperability, service unavailability, security vulnerabilities or from your reliance on the quality, accuracy, or reliability of data gathered by our service.
               The Karma Hunter entities make no claims or promises with respect to any third party. Accordingly, the Karma Hunter entities are not liable to you for any personal injury, loss or damage that might arise from any such third party’s actions or omissions, including, for example, data pipeline interruptions, identity or personal information. Your purchase and use of products or services offered by third parties through the service is at your own discretion and risk.
               Your sole and exclusive right and remedy in case of dissatisfaction with the service, related services, or any other grievance shall be your termination and discontinuation of access to, or use of the service.
               The Karma Hunter entities will not be liable for any
            <p>
               (i) Indirect, Special, Incidental, Punitive, Exemplary, Reliance, Or Consequential Damages,
            </p>
            <p>
               (ii) Loss of Profits or Revenue,
            </p>
            <p>
               (iii) Business Interruption,
            </p>
            <p>
               (iv) Loss of Information or Data;
            </p>
            <p>
            </p>
            </p>
        </div>
            </div>
    </Layout>
)

export default TermsPage
